<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar
          color="accent"
          dark
          style="height: 20px !important"
        />
        <v-card-title>
          <span class="dialogText">
              Pentru a putea genera Anexa 3 este necesar sa setezi o serie si un numar de start in ecranul
              Configurari!
          </span>
        </v-card-title>
        <v-card-actions class="justify-end">
          <!-- <v-btn
            class="mainButtonNo"
            @click="close()"
          >INCHIDE</v-btn> -->
          <!-- <router-link :to="`/wasteRecords`"> -->
          <v-btn class="mainButtonYes" text :to="`/configurations`">Mergi la Configurari</v-btn>
          <!-- </router-link> -->
          <v-btn
            class="mainButtonNo"
            @click="close()"
          >RENUNTA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  props: [
   'infoText',
   'closeDialog'
  ],
  data: () => ({
    dialog: true,
  }),
  methods: {
    close() {
      this.closeDialog()
    }
  },
  computed: {
  },
  created() {
  }
};
</script>