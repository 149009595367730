<template>
<v-layout row justify="center">
  <v-dialog v-model="dialog" persistent max-width="1800px" @keydown.esc="close()">
    <v-card>
      <v-card-title>
        <div class="mb-6" style="font-size: 1.175rem;">
          Genereaza Anexa 3 pentru <span class="font-bold"> Cod deseu {{model.wasteInfo.code}} din {{ parseDate(model.wasteInfo.registerDate) }}</span></div>
        <!-- <span>Genereaza Anexa 3 pentru </span> -->
        <!-- <span class="headline"> Cod deseu {{model.wasteInfo.code}} din {{ parseDate(model.registerDate) }}</span> -->
      </v-card-title>
      <v-card-text>
        <v-form class="w-full" ref="form">
          <v-container style="background-color: #F9F8F8;" class="elevation-3 mb-3 rounded-lg">
            <v-row dense>
              <v-col cols="12">
                <span style="color: #000; font-size: 1rem">Date privind deseul</span>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Cod deseu *" v-model="model.wasteInfo.code"
                  label="Cod deseu *" :disabled="true" />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field type="text" placeholder="Nume deseu *" v-model="model.wasteInfo.name"
                  label="Nume deseu *" :disabled="true" />
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="number" label="Cantitate *" placeholder="Cantitate" v-model="model.wasteInfo.quantity" required
                  :max="999999.99" oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;" :rules="[
                    v => !!v || `Completeaza valoarea`,
                    v => Number(v) >= 0 || `Numarul trebuie sa fie pozitiv`,
                    v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Numarul trebuie sa aiba cel mult 2 zecimale`
                  ]" :disabled="true"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Unitate de masura *" v-model="model.wasteInfo.unitMeasure"
                  label="Unitate de masura *" :disabled="true"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Destinatia *" v-model="model.wasteInfo.destination"
                  label="Destinatia *" :disabled="true"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-dialog max-width="300px" persistent v-model="setRegisterDate">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" v-model="computedRegisterDate" label="Data incarcare *"
                      required :rules="[v => !!v || `Completeaza data incarcare`]" prepend-icon="mdi-calendar" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="model.wasteInfo.registerDate" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setRegisterDate = false">Cancel</v-btn>
                      <v-btn color="primary" @click="setRegisterDate = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="2">
                <v-dialog max-width="300px" persistent v-model="setArrivalDate">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" v-model="computedArrivalDate" label="Data descarcare *"
                      required :rules="[v => !!v || `Completeaza data descarcare`]" prepend-icon="mdi-calendar" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="model.wasteInfo.arrivalDate" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setArrivalDate = false">Cancel</v-btn>
                      <v-btn color="primary" @click="setArrivalDate = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
          <v-container style="background-color: #F9F8F8;" class="elevation-3 mb-3 rounded-lg">
            <v-row dense>
              <v-col cols="12">
                <span style="color: #000; font-size: 1rem">Date privind expeditorul</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Nume expeditor *" v-model="model.sender.companyName"
                  label="Nume expeditor *" required :rules="[v => !!v || `Completeaza nume expeditor`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="CUI *" v-model="model.sender.vatId"
                  label="CUI *" required :rules="[v => !!v || `Completeaza CUI expeditor`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="Reg Comertului" v-model="model.sender.regCom"
                  label="Reg Comertului" />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Adresa *" v-model="model.sender.companyAddress"
                  label="Adresa *" required :rules="[v => !!v || `Completeaza adresa expeditor`]"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Localitate *" v-model="model.sender.companyCity"
                  label="Localitate *" required :rules="[v => !!v || `Completeaza localitate expeditor`]"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Judet *" v-model="model.sender.companyCounty"
                  label="Judet *" required :rules="[v => !!v || `Completeaza judet expeditor`]"/>
              </v-col>
              <v-col cols="12">
                <span style="color: #000; font-size: 1rem">Punct de lucru expeditor</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Adresa *" v-model="model.sender.wpAddress"
                  label="Adresa *" required :rules="[v => !!v || `Completeaza adresa punct de lucru`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="Localitate *" v-model="model.sender.wpCity"
                  label="Localitate *" required :rules="[v => !!v || `Completeaza localitate punct de lucru`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="Judet *" v-model="model.sender.wpCounty"
                  label="Judet *" required :rules="[v => !!v || `Completeaza judet punct de lucru`]"/>
              </v-col>
              <v-col cols="12">
                <span style="color: #000; font-size: 1rem">Autorizatie mediu expeditor</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Numar autorizatie mediu" v-model="model.sender.noAuth"
                  label="Numar autorizatie mediu" />
              </v-col>
              <v-col cols="12" md="2">
                <!-- <v-text-field type="text" placeholder="Data emitere autorizatie *" v-model="model.sender.startDateAuth"
                  label="Data emitere autorizatie *" required/> -->
                <v-dialog max-width="300px" persistent v-model="setWPStartAuthDate">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" :value="computedStartDateAuth" label="Data emitere autorizatie"
                      prepend-icon="mdi-calendar" readonly clearable @click:clear="model.sender.startDateAuth = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="model.sender.startDateAuth" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setWPStartAuthDate = false">Cancel</v-btn>
                      <v-btn color="primary" @click="setWPStartAuthDate = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="2">
                <!-- <v-text-field type="text" placeholder="Data expirare autorizatie *" v-model="model.sender.endDateAuth"
                  label="Data expirare autorizatie *" required/> -->
                <v-dialog max-width="300px" persistent v-model="setWPEndAuthDate">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" :value="computedEndDateAuth" label="Data expirare autorizatie"
                      prepend-icon="mdi-calendar" readonly clearable @click:clear="model.sender.endDateAuth = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="model.sender.endDateAuth" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setWPEndAuthDate = false">Cancel</v-btn>
                      <v-btn color="primary" @click="setWPEndAuthDate = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
          <v-container style="background-color: #F9F8F8;" class="elevation-3 mb-3 rounded-lg">
            <v-row dense>
              <v-col cols="12">
                <span style="color: #000; font-size: 1rem">Date privind transportatorul</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Nume transportator *" v-model="model.transporter.name"
                  label="Nume transportator *" required :rules="[v => !!v || `Completeaza nume transportator`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="CUI *" v-model="model.transporter.vatId"
                  label="CUI *" required :rules="[v => !!v || `Completeaza CUI transportator`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="Reg Comertului" v-model="model.transporter.regCom"
                  label="Reg Comertului" />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Adresa *" v-model="model.transporter.address"
                  label="Adresa *" required :rules="[v => !!v || `Completeaza adresa transportator`]"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Localitate *" v-model="model.transporter.city"
                  label="Localitate *" required :rules="[v => !!v || `Completeaza localitate transportator`]"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Judet *" v-model="model.transporter.county"
                  label="Judet *" required :rules="[v => !!v || `Completeaza judet trasnportator`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" v-model="model.transporter.driver"
                  label="Nume si prenume sofer" v-if="wasteRecord.partnerDriver"/>

                <v-select v-else v-model="partnerDriver" label="Nume si prenume sofer"
                  :items="partnerById(wasteRecord.partnerTransportator) ? partnerById(wasteRecord.partnerTransportator).partnerDrivers : []"
                  item-text="name" item-value="_id" return-object clearable>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai niciun sofer adaugat pentru acest partener.
                        <AddButton :onClick="() => { openPartnerDialogForUpdate(partnerById(wasteRecord.partnerTransportator), 'drivers') }" caption="Adauga sofer" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item
                    v-if="partnerById(wasteRecord.partnerTransportator) && partnerById(wasteRecord.partnerTransportator).partnerDrivers.length">
                    <AddButton :onClick="() => { openPartnerDialogForUpdate(partnerById(wasteRecord.partnerTransportator), 'drivers') }" caption="Adauga sofer nou" />
                  </template>
                </v-select>

                
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="Serie si nr CI" v-model="model.transporter.driverCI"
                  label="Serie si nr CI"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" v-model="model.transporter.carPlate"
                  label="Nr AUTO" v-if="wasteRecord.partnerCar"/>

                <v-select v-else v-model="partnerCar" label="Nr AUTO"
                  :items="partnerById(wasteRecord.partnerTransportator) ? partnerById(wasteRecord.partnerTransportator).partnerCars : []"
                  item-text="plate" item-value="_id" return-object clearable>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai nicio masina adaugata pentru acest partener.
                        <AddButton :onClick="() => { openPartnerDialogForUpdate(partnerById(wasteRecord.partnerTransportator), 'cars') }" caption="Adauga masina" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item
                    v-if="partnerById(wasteRecord.partnerTransportator) && partnerById(wasteRecord.partnerTransportator).partnerCars.length">
                    <AddButton :onClick="() => { openPartnerDialogForUpdate(partnerById(wasteRecord.partnerTransportator), 'cars') }" caption="Adauga masina noua" />
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" v-model="model.transporter.transportAuthNo"
                  label="Nr Licenta transport" />
              </v-col>
              <v-col cols="12" md="2">
                
                <v-dialog max-width="300px" persistent v-model="setTransportAuthExpDate">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" :value="computedTransportAuthExpDate" label="Data expirare licenta"
                      prepend-icon="mdi-calendar" readonly clearable @click:clear="model.transporter.transportAuthExpDate = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="model.transporter.transportAuthExpDate" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setTransportAuthExpDate = false">Cancel</v-btn>
                      <v-btn color="primary" @click="setTransportAuthExpDate = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
          <v-container style="background-color: #F9F8F8;" class="elevation-3 mb-3 rounded-lg">
            <v-row dense>
              <v-col cols="12">
                <span style="color: #000; font-size: 1rem">Date privind destinatarul</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Nume destinatar *" v-model="model.receiver.name"
                  label="Nume destinatar *" required :rules="[v => !!v || `Completeaza nume destinatar`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="CUI *" v-model="model.receiver.vatId"
                  label="CUI *" required :rules="[v => !!v || `Completeaza CUI destinatar`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="Reg Comertului" v-model="model.receiver.regCom"
                  label="Reg Comertului" />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Adresa *" v-model="model.receiver.address"
                  label="Adresa *" required :rules="[v => !!v || `Completeaza adresa destinatar`]"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Localitate *" v-model="model.receiver.city"
                  label="Localitate *" required :rules="[v => !!v || `Completeaza localitate destinatar`]"/>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field type="text" placeholder="Judet *" v-model="model.receiver.county"
                  label="Judet *" required :rules="[v => !!v || `Completeaza judet destinatar`]"/>
              </v-col>
              <v-col cols="12">
                <span style="color: #000; font-size: 1rem">Punct de lucru destinatar</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Adresa *" v-model="model.receiver.wpAddress"
                  label="Adresa *" required :rules="[v => !!v || `Completeaza adresa punct de lucru`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="Localitate *" v-model="model.receiver.wpCity"
                  label="Localitate *" required :rules="[v => !!v || `Completeaza localitate punct de lucru`]"/>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field type="text" placeholder="Judet *" v-model="model.receiver.wpCounty"
                  label="Judet *" required :rules="[v => !!v || `Completeaza judet punct de lucru`]"/>
              </v-col>
              <v-col cols="12">
                <span style="color: #000; font-size: 1rem">Autorizatie mediu destinatar</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field type="text" placeholder="Numar autorizatie mediu" v-model="model.receiver.noAuth"
                  label="Numar autorizatie mediu"/>
              </v-col>
              <v-col cols="12" md="2">
                <!-- <v-text-field type="text" placeholder="Data emitere autorizatie *" v-model="model.receiver.startDateAuth"
                  label="Data emitere autorizatie *" required/> -->
                <v-dialog max-width="300px" persistent v-model="setReceiverStartAuthDate">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" :value="computedReceiverStartDateAuth" label="Data emitere autorizatie"
                      prepend-icon="mdi-calendar" readonly clearable @click:clear="model.receiver.startDateAuth = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="model.receiver.startDateAuth" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setReceiverStartAuthDate = false">Cancel</v-btn>
                      <v-btn color="primary" @click="setReceiverStartAuthDate = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="2">
                <!-- <v-text-field type="text" placeholder="Data expirare autorizatie *" v-model="model.receiver.endDateAuth"
                  label="Data expirare autorizatie *" required/> -->
                <v-dialog max-width="300px" persistent v-model="setReceiverEndAuthDate">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" :value="computedReceiverEndDateAuth" label="Data expirare autorizatie"
                      prepend-icon="mdi-calendar" readonly clearable @click:clear="model.receiver.endDateAuth = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="model.receiver.endDateAuth" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setReceiverEndAuthDate = false">Cancel</v-btn>
                      <v-btn color="primary" @click="setReceiverEndAuthDate = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>

        </v-form>
      </v-card-text>
      <v-card-actions class="cardActions">
        <v-progress-circular indeterminate color="primary" v-if="loadingAnnex"></v-progress-circular>
        <v-btn type="button" class="mainButtonYes" text @click="save()" :disabled="loadingAnnex">Genereaza Anexa 3</v-btn>
        <v-btn type="button" class="mainButtonNo" color="red" @click="close()" :disabled="loadingAnnex">Renunta</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <CreatePartner v-if="partnerDialog" :closeDialog="closePartnerDialog" :workingPoint="annexWorkingPoint"
      :createCb="getPartners"
      :partnerField="partnerField" :partnerToUpdate="partnerToUpdate" :updateCarsAndDrivers="updateCarsAndDrivers"/>
</v-layout>
</template>
<script>
import moment from "moment-timezone";
import CreatePartner from './Create/CreatePartner.vue';
import AddButton from '../Buttons/AddButtonText.vue';
export default {
  components: {
    CreatePartner,
    AddButton
  },
  props: [
    'wasteRecord',
    'closeDialog',
    'annexWorkingPoint'
  ],
  data: () => ({
    // isGeneratingAnnex: false,
    dialog: true,
    setRegisterDate: false,
    setArrivalDate: false,
    setWPStartAuthDate: false,
    setWPEndAuthDate: false,
    setTransportAuthExpDate: false,
    setReceiverStartAuthDate: false,
    setReceiverEndAuthDate:false,
    model: {},
    partnerToUpdate: null,
    partnerDialog: false,
    partnerField: null,
    updateCarsAndDrivers: false,
    partnerCar: null,
    partnerDriver: null
  }),
  watch: {
    partnerCar(newVal) {
    
      if(newVal) {
        this.model.transporter.carId = this.partnerCar._id
        this.model.transporter.carPlate = this.partnerCar.plate
        this.model.transporter.transportAuthNo = this.partnerCar.transportAuthNo || null
        this.model.transporter.transportAuthExpDate = this.partnerCar.transportAuthExpDate 
          ? moment(this.partnerCar.transportAuthExpDate).format("YYYY-MM-DD") 
          : null
      } else {
        this.model.transporter.carId = null
        this.model.transporter.carPlate = null
        this.model.transporter.transportAuthNo = null
        this.model.transporter.transportAuthExpDate = null
      }
    },
    partnerDriver(newVal) {
      if(newVal) {
        this.model.transporter.driverId = this.partnerDriver._id
        this.model.transporter.driver = this.partnerDriver.name
        this.model.transporter.driverCI = this.partnerDriver.driverCI || null
      } else {
        this.model.transporter.driverId = null
        this.model.transporter.driver = null
        this.model.transporter.driverCI = null
      }
    }
  },
  computed: {
    partners() {
      return this.$store.state.partners.partners
    },
    computedRegisterDate () {
      return this.model.wasteInfo.registerDate ? this.parseDate(this.model.wasteInfo.registerDate) : ''
    },
    computedArrivalDate () {
      return this.model.wasteInfo.arrivalDate ? this.parseDate(this.model.wasteInfo.arrivalDate) : ''
    },
    computedStartDateAuth () {
      return this.model.sender.startDateAuth ? this.parseDate(this.model.sender.startDateAuth) : ''
    },
    computedEndDateAuth () {
      return this.model.sender.endDateAuth ? this.parseDate(this.model.sender.endDateAuth) : ''
    },
    computedTransportAuthExpDate () {
      return this.model.transporter.transportAuthExpDate ? this.parseDate(this.model.transporter.transportAuthExpDate) : ''
    },
    computedReceiverStartDateAuth () {
      return this.model.receiver.startDateAuth ? this.parseDate(this.model.receiver.startDateAuth) : ''
    },
    computedReceiverEndDateAuth () {
      return this.model.receiver.endDateAuth ? this.parseDate(this.model.receiver.endDateAuth) : ''
    },
    loadingAnnex() {
      return this.$store.state.wasteRecords.loadingAnnex
    },
    
  },
  methods: {
    partnerById(partner) {
      if (!partner) {
        return
      }
      if (partner._id) {
        partner = partner._id
      }
      return this.partners.find(p => {
        return p._id == partner
      })
    },
    closePartnerDialog() {
      this.partnerDialog = false
      this.partnerToUpdate = null
      this.partnerField = null
      this.updateCarsAndDrivers = false
    },
    openPartnerDialogForUpdate(partner, field) {
      this.partnerToUpdate = partner;
      this.partnerField = field;
      this.updateCarsAndDrivers = true
      this.partnerDialog = true
    },
    getPartners(fieldToAdd) {
      this.$store.dispatch('fetchPartners', {
        cb: (data) => {
          if (fieldToAdd && fieldToAdd == 'cars') {
            let transporter = data.find(p => p._id == this.wasteRecord.partnerTransportator._id)
            if(transporter && transporter.partnerCars.length) {
              this.partnerCar = transporter.partnerCars[transporter.partnerCars.length - 1]
            }
          }
          if (fieldToAdd && fieldToAdd == 'drivers') {
            let transporter = data.find(p => p._id == this.wasteRecord.partnerTransportator._id)
            if(transporter && transporter.partnerDrivers.length) {
              this.partnerDriver = transporter.partnerDrivers[transporter.partnerDrivers.length - 1]
            }
          }
        }
      });
    },
    close() {
      this.closeDialog()
    },
    checkUpdates() {
      let transporter = this.wasteRecord.partnerTransportator ? this.partners.find((p) => p._id === this.wasteRecord.partnerTransportator._id) : null;
      let receiver = this.wasteRecord.partnerDestination ? this.partners.find((p) => p._id === this.wasteRecord.partnerDestination._id) : null;
      let car = transporter && this.wasteRecord.partnerCar ? transporter.partnerCars.find((c) => c._id === this.wasteRecord.partnerCar._id) : null;
      let driver = transporter && this.wasteRecord.partnerDriver ? transporter.partnerDrivers.find((c) => c._id === this.wasteRecord.partnerDriver._id) : null;
      let updates = {}

      // set driver updates
      if(driver && !driver.driverCI) {
        if(this.model.transporter.driver == driver.name && this.model.transporter.driverCI) {
          updates = {
            driver: { id: driver._id, props: { driverCI: this.model.transporter.driverCI }}
          }
        }
      }
      // set car updates
      if(car && this.model.transporter.carPlate === car.plate) {
        let props = {}
        if(!car.transportAuthNo && this.model.transporter.transportAuthNo) {
          props = {transportAuthNo: this.model.transporter.transportAuthNo}
        }
        if(!car.transportAuthExpDate && this.model.transporter.transportAuthExpDate) {
          props = {...props, transportAuthExpDate: this.model.transporter.transportAuthExpDate}
        }
        if(Object.keys(props).length > 0) {
          updates = { ...updates, car: { id: car._id, props: props}}
        }
      }
      // set partnerDestination updates
      if(receiver) {
        let props = {}
        if(!receiver.regCom && this.model.receiver.regCom) {
          props = { regCom: this.model.receiver.regCom}
        }
        if(!receiver.noAuth && this.model.receiver.noAuth) {
          props = {...props, noAuth: this.model.receiver.noAuth}
        }
        if(!receiver.startDateAuth && this.model.receiver.startDateAuth) {
          props = {...props, startDateAuth: this.model.receiver.startDateAuth}
        }
        if(!receiver.endDateAuth && this.model.receiver.endDateAuth) {
          props = {...props, endDateAuth: this.model.receiver.endDateAuth}
        }
        if(Object.keys(props).length > 0) {
          updates = { ...updates, partner: { id: receiver._id, props: props}}
        }
        // set partnerTransporter updates
        if(transporter && !transporter.regCom && receiver._id != transporter._id) {
          if(this.model.transporter.regCom) {
            updates = {
              partnerTransportator: { id: transporter._id, props: { regCom: this.model.transporter.regCom }}
            }
          }
        }
      }
    
      // set company updates
      let company = this.annexWorkingPoint.company || null
      if(company && !company.regCom && this.model.sender.regCom) {
        updates = {...updates, company: { id: company._id, props: {regCom: this.model.sender.regCom}}}
      }
      //set wp updates
      let wpProps = {}
      if(!this.annexWorkingPoint.noAuth && this.model.sender.noAuth) {
        wpProps = {...wpProps, noAuth: this.model.sender.noAuth}
      }
      if(!this.annexWorkingPoint.startDateAuth && this.model.sender.startDateAuth) {
        wpProps = {...wpProps, startDateAuth: this.model.sender.startDateAuth}
      }
      if(!this.annexWorkingPoint.endDateAuth  && this.model.sender.endDateAuth) {
        wpProps = {...wpProps, endDateAuth: this.model.sender.endDateAuth}
      }
      if(Object.keys(wpProps).length > 0) {
        updates = { ...updates, workingPoint: { id: this.annexWorkingPoint._id, props: wpProps}}
      }

      return updates;
    },
    save() {
      if (this.$refs.form.validate()) {
        
        let formData = {
          annex: this.model,
          wasteRecord: this.wasteRecord,
          workingPoint: this.annexWorkingPoint
        }
        let updates = this.checkUpdates();
        formData['updates'] = updates;

        this.$store.dispatch('generateAnnexPDF', {
          model: formData,
          cb: () => {
            this.close()
          }
        });
      }

    },
    parseDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    
  },
  created() {
    let transporter = this.wasteRecord.partnerTransportator ? this.partners.find((p) => p._id === this.wasteRecord.partnerTransportator._id) : null;
    let receiver = this.wasteRecord.partnerDestination ? this.partners.find((p) => p._id === this.wasteRecord.partnerDestination._id) : null;
    let car = transporter && this.wasteRecord.partnerCar ? transporter.partnerCars.find((c) => c._id === this.wasteRecord.partnerCar._id) : null;
    let driver = transporter && this.wasteRecord.partnerDriver ? transporter.partnerDrivers.find((c) => c._id === this.wasteRecord.partnerDriver._id) : null;

    this.model = {
      wasteInfo: {
        comments: this.wasteRecord.comments || null,
        annexSeries: this.annexWorkingPoint.annexSeries,
        annexStartNumber: this.annexWorkingPoint.annexStartNumber,
        recordId: this.wasteRecord._id || null,
        code: this.wasteRecord.wasteCode.code || null,
        name: this.wasteRecord.wasteCode.name || null,
        quantity: this.wasteRecord.quantity || null,
        unitMeasure: this.wasteRecord.unitMeasure.code || null,
        destination: this.wasteRecord.codValorificare ? 'VALORIFICARE' : this.wasteRecord.codEliminare ? 'ELIMINARE' :  null,
        registerDate: moment(this.wasteRecord.registerDate).format("YYYY-MM-DD"),
        arrivalDate: null
      },
      sender: {
        _id: this.annexWorkingPoint.company._id || null,
        companyName: this.annexWorkingPoint.company.companyName || null,
        vatId: this.annexWorkingPoint.company.vatId || null,
        regCom: this.annexWorkingPoint.company.regCom || null,
        companyAddress: this.annexWorkingPoint.company.companyAddress || null,
        companyCity: this.annexWorkingPoint.company.city || null,
        companyCounty: this.annexWorkingPoint.company.county || null,
        wpId: this.annexWorkingPoint._id || null,
        wpAddress: this.annexWorkingPoint.address || null,
        wpCity: this.annexWorkingPoint.city || null,
        wpCounty: this.annexWorkingPoint.county || null,
        noAuth: this.annexWorkingPoint.noAuth || null,
        startDateAuth: this.annexWorkingPoint.startDateAuth ? moment(this.annexWorkingPoint.startDateAuth).format("YYYY-MM-DD") : null,
        endDateAuth: this.annexWorkingPoint.endDateAuth ? moment(this.annexWorkingPoint.endDateAuth).format("YYYY-MM-DD") : null
      },
      transporter: {
        _id: transporter ? transporter._id || null : null,
        name: transporter ? transporter.name || null : null,
        vatId: transporter ? transporter.vatId || null : null,
        regCom: transporter ? transporter.regCom || null : null,
        address: transporter ? transporter.address || null : null,
        city: transporter ? transporter.city || null : null,
        county: transporter ? transporter.county || null : null,
        driverId: driver ? driver._id || null : null,
        driver: driver ? driver.name || null : null,
        driverCI: driver ? driver.driverCI || null : null,
        carId: car ? car._id || null : null,
        carPlate: car ? car.plate || null : null,
        transportAuthNo: car ? car.transportAuthNo || null : null,
        transportAuthExpDate: car && car.transportAuthExpDate ? moment(car.transportAuthExpDate).format("YYYY-MM-DD") : null,
      },
      receiver: {
        _id: receiver ? receiver._id || null : null,
        name: receiver ? receiver.name || null : null,
        vatId: receiver ? receiver.vatId || null : null,
        regCom: receiver ? receiver.regCom || null : null,
        address: receiver ? receiver.address || null : null,
        city: receiver ? receiver.city || null : null,
        county: receiver ? receiver.county || null : null,
        wpAddress: receiver ? receiver.address || null : null,
        wpCity: receiver ? receiver.city || null : null,
        wpCounty: receiver ? receiver.county || null : null,
        noAuth: receiver ? receiver.noAuth || null : null,
        startDateAuth: receiver && receiver.startDateAuth ? moment(receiver.startDateAuth).format("YYYY-MM-DD") : null,
        endDateAuth: receiver && receiver.endDateAuth ? moment(receiver.endDateAuth).format("YYYY-MM-DD") : null
      },
      
    }
  }
}

</script>
<style>

</style>