<template>
  <div style="margin: 10px">
    <LayoutHeader title="Evidenta deseuri" :dontDistrurbFree="true" />
    <!-- <section-headline>
        Evidenta deseuri
    </section-headline> -->
    <div class="layoutBody">
      <v-select v-model="currentWorkingPoint" :items="workingPoints" item-text="displayName"
        label="Alege punctul de lucru" item-value="_id" return-object @input="changeContext()" />
       
      <v-layout v-if="currentWorkingPoint && !userDisabled && canPerformBasicCRUD && canAddRecord(currentWorkingPoint)">
        <div class="grid" v-if="activeWasteCodesPerWorkingPoint.length < 8">
          <p> Adauga evidenta pentru </p>
          <v-row class="buttonRow alignedRow">
            <v-tooltip top v-for="(wcPWP, idx) in activeWasteCodesPerWorkingPoint" :key="idx">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="openWasteRecordDialog(wcPWP)" class="text-gray-500 primary marginedButton" v-bind="attrs"
                  v-on="on">
                  <v-icon left dark>mdi-plus</v-icon>
                  <span>{{ wcPWP.alias || wcPWP.wasteCode.code }}</span>
                </v-btn>
              </template>
              <span>{{ wcPWP.wasteCode.name }}</span>
            </v-tooltip>
          </v-row>
        </div>
        <v-select v-else v-model="wcToAdd" label="Adauga evidenta pentru" :items="activeWasteCodesPerWorkingPoint"
          item-text="displayName" return-object @input="openWasteRecordDialog(wcToAdd)" />
      </v-layout>
      <v-layout class="text-left" v-if="currentWorkingPoint">
        <v-col cols="12">
          <v-data-table v-if="wasteRecords" class="elevation-0" :headers="wasteCodeHeaders" :items="wasteRecords"
            :options.sync="options" :footer-props="{
              itemsPerPageOptions: [25, 50, 100],
            }">
            <template v-slot:top>
              <!-- <sub-header>
                Filtreaza
            </sub-header> -->
              <v-row class="buttonRow" align="baseline">
                <div class="d-flex flex-wrap w_auto w_md_90pr">
                  <v-combobox clearable label="Filtreaza dupa cod deseu" v-model="wasteCodeFilter" class="left mr-0 mr-md-2 w_full w_md_auto"
                    density="compact" :items="activeWasteCodesPerWorkingPoint" item-text="displayName" underlined
                    item-value="wasteCode.code" color="primary">
                  </v-combobox>
                  <v-combobox clearable label="Filtreaza tip operatiune" v-model="discardTypeFilter" class="left mr-0 mr-md-2 w_full w_md_auto"
                    density="compact" :items="['ELIMINARE', 'VALORIFICARE']" underlined color="primary">
                  </v-combobox>
                </div>
                <div class="d-flex w_full w_md_10pr justify-start justify-md-center">
                  <v-btn @click="exportRecords()" class="text-gray-500" color="secondary" :disabled="loadingExport">
                    <v-icon class="mr-1">
                      mdi-cloud-download-outline
                    </v-icon>
                    <span>Exporta</span>
                  </v-btn>
                  <v-progress-circular indeterminate color="primary" v-if="loadingExport"></v-progress-circular>
                </div>
              </v-row>
              <v-row class="buttonRow">
                <v-menu
                  v-model="dateRangeFilter.show"
                  :close-on-content-click="false"
                  :return-value.sync="pickerDates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary"
                      @click="dateRangeFilter.show = true" 
                      class="text-gray-500  marginedButton">
                      <span v-if="!dateRangeFilter.text" style="min-width: 230px;">
                        Selecteaza perioada
                      </span>
                      <span v-else style="min-width: 230px;">{{ dateRangeFilter.text }}</span>
                    </v-btn>
                  </template>
                  <v-date-picker v-model="pickerDates" no-title scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="dateRangeFilter.show = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="secondary" @click="savePickedDates()">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-btn color="secondary" v-for="filter in dateFilters" :key="filter.text"
                  @click="dateFilter = filter.value" :disabled="filterIsActive('registerDate', filter.value)"
                  class="text-gray-500  marginedButton">
                  {{ filter.text }}
                </v-btn>
              </v-row>
            </template>
            <template v-slot:[`item.registerDate`]="props">
              {{ parseDate(props.item.registerDate) }}
            </template>
            <template v-slot:[`item.operationCode`]="props">
              <v-tooltip bottom max-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> 
                    {{ props.item.codValorificare ? props.item.codValorificare.code : (props.item.codEliminare ?
            props.item.codEliminare.code : '-') }}
                  </span>
                </template>
                <span>
                  {{ props.item.codValorificare 
                    ? props.item.codValorificare.code + ' (' + props.item.codValorificare.name + ')'
                    : (props.item.codEliminare
                    ?  props.item.codEliminare.code + ' (' + props.item.codEliminare.name + ')' 
                    : '-') }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.destination`]="props">
              <v-tooltip bottom max-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> 
                    {{ props.item.destination.code }}
                  </span>
                </template>
                <span>{{ props.item.destination.code + ' (' + props.item.destination.name + ')' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.comments`]="props">
              <v-tooltip bottom max-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <span class="twoRowSpan" v-bind="attrs" v-on="on"> 
                    <!-- Deseuri predate catre acest operator cand am vrut eu  si din ce motiv am vrut eu. Nu trebuie sa dau explicatii. -->
                     {{ props.item.comments }}
                  </span>
                </template>
                <span>
                  <!-- Deseuri predate catre acest operator cand am vrut eu  si din ce motiv am vrut eu. Nu trebuie sa dau explicatii. -->
                   {{ props.item.comments }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.annexActions`]="props">

                <v-tooltip top v-if="!props.item.annexUrl && checkIfAnnexAvailable(props.item)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="openAnnexDialog(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled">
                      <v-icon color="primary">mdi-plus-box</v-icon>
                    </v-btn>
                  </template>
                  <span>Genereaza anexa 3</span>
                </v-tooltip>
                <div class="flex" v-else-if="props.item.annexUrl && checkIfAnnexAvailable(props.item)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="downloadAnnex(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled">
                        <v-icon color="secondary">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Descarca anexa 3</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="deleteAnnex(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled">
                        <v-icon color="error">mdi-trash-can</v-icon>
                      </v-btn>
                    </template>
                    <span>Sterge anexa 3</span>
                  </v-tooltip>
                </div>
                
            </template>
            <template v-slot:[`item.receiptActions`]="props">
              <v-tooltip top v-if="!props.item.deliveryProofUrl">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn icon @click="openAnnexDialog(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled"> -->
                  <v-btn icon @click="uploadDeliveryProof(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled">
                    <v-icon color="secondary">mdi-upload</v-icon>
                  </v-btn>
                </template>
                <span>Ataseaza dovada predare</span>
              </v-tooltip>
              <div class="flex" v-else >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <v-btn icon @click="downloadAnnex(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled"> -->
                    <v-btn icon @click="downloadDeliveryProof(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled">
                      <v-icon color="secondary">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Descarca dovada predare</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="deleteDeliveryProof(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled">
                      <v-icon color="error">mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Sterge dovada predare</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.actions`]="props" v-if="canPerformBasicCRUD && canAddRecord(currentWorkingPoint)">
              <p class="tableData">
              <div class="flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="openUpdateDialog(props.item)" v-bind="attrs" v-on="on" :disabled="userDisabled">
                      <v-icon color="primary">mdi-pen</v-icon>
                    </v-btn>
                  </template>
                  <span>Rectifica</span>
                </v-tooltip>
                
                <v-tooltip top v-if="notFinalized(props.item)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="finalizeWasteRecord(props.item)" v-bind="attrs" v-on="on"
                      :disabled="userDisabled">
                      <v-icon color="primary">mdi-check-bold</v-icon>
                    </v-btn>
                  </template>
                  <span>Finalizeaza</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="openDeleteDialog(props.item)" v-bind="attrs" v-on="on"
                      :disabled="userDisabled">
                      <v-icon color="error">mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Sterge</span>
                </v-tooltip>
              </div>
              </p>
            </template>
          </v-data-table>
        </v-col>
      </v-layout>
      <v-row v-else>
        <sub-header>Selecteaza un punct de lucru!</sub-header>
      </v-row>
    </div>
    <v-text-field style="display: none" hidden id="uploadFile" type="file"
      accept="application/pdf, image/png, image/jpeg" hide-details></v-text-field>
    <DeleteDialog v-if="deleteDialog" :closeDialog="closeDeleteDialog" :deleteAction="deleteWasteRecord"
      itemName="evidenta" :item="currentWasteRecord" />
    <CreateWasteRecord v-if="wasteRecordDialog" :closeDialog="closeWasteRecordDialog"
      :carTypes="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :wasteCodePWP="currentWasteCodePWP"
      :workingPoint="currentWorkingPoint" :partners="partnersByWorkingPoint(currentWorkingPoint)"
      :updating="updating" />
    <GenerateAnnexDialog v-if="showGenerateAnnexDialog" :wasteRecord="currentWasteRecord"
      :closeDialog="closeGenerateAnnexDialog" :annexWorkingPoint="annexWorkingPoint"
    />
    <GoToConfigsDialog v-if="showGoToConfigsDialog" :closeDialog="closeGoToConfigsDialog"/>
  </div>
</template>
<script>
import moment from "moment-timezone";
import {
  ListCategories
} from '../common/ListCategories';
import AddButton from "../components/Buttons/AddButton.vue";
import CreateWasteRecord from '../components/Modals/Create/CreateWasteRecord.vue';
import DeleteDialog from '../components/Modals/DeleteDialog.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import SubHeader from '../components/Typography/SubHeader.vue';
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
import GenerateAnnexDialog from '../components/Modals/GenerateAnnexDialog.vue';
import GoToConfigsDialog from '../components/Modals/GoToConfigsDialog.vue';

export default {
  components: {
    LayoutHeader,
    SectionHeadline,
    CreateWasteRecord,
    AddButton,
    SubHeader,
    DeleteDialog,
    GenerateAnnexDialog,
    GoToConfigsDialog
  },
  data() {
    return {
      file: null,
      currentWasteRecord: null,
      currentWorkingPoint: null,
      annexWorkingPoint: null,
      options: {},
      expanded: [],
      wasteRecordDialog: false,
      currentWasteCodePWP: null,
      updating: false,
      wcToAdd: null,
      options: {},
      filters: {},
      wasteCodeHeaders: [{
        text: 'Cod deseu',
        value: 'wasteCode.code',
        width: '7%'
      },
      {
        text: 'Nume deseu',
        value: 'wasteCode.name',
        width: '10%'
      },
      {
        text: 'Data operatiune',
        value: 'registerDate'
      },
      {
        text: 'Cod operatiune',
        value: 'operationCode'
      },
      {
        text: 'Cantitate',
        value: 'quantity'
      },
      {
        text: 'U. M.',
        value: 'unitMeasure.code'
      },
      // {
      //   text: 'Stare fizica',
      //   value: 'stareFizica.code'
      // },
      // {
      //   text: 'Tip stocare',
      //   value: 'stockingType.code'
      // },
      {
        text: 'Destinatie',
        value: 'destination'
      },
      // {
      //   text: 'Tip transport',
      //   value: 'carType.code'
      // },
      {
        text: 'Destinatar',
        value: 'partnerDestination.name'
      },
      {
        text: 'Transportator',
        value: 'partnerTransportator.name'
      },
      {
        text: 'Mijloc de transport',
        value: 'partnerCar.plate'
      },
      {
        text: 'Sofer',
        value: 'partnerDriver.name'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Observatii',
        value: 'comments',
        width: '225px',
        align: 'start'
      },
      {
        text: 'Anexa 3',
        value: 'annexActions'
      },
      {
        text: 'Dovada predare',
        value: 'receiptActions'
      },
      {
        text: 'Actiuni',
        value: 'actions'
      },
      ],
      dateFilters: [{
        text: 'Luna curenta',
        value: 'THIS_MONTH',
        
      },
      {
        text: 'Luna trecuta',
        value: 'LAST_MONTH',
       
      },
      {
        text: 'Anul curent',
        value: 'THIS_YEAR',
        
      },
      {
        text: 'Anul trecut',
        value: 'LAST_YEAR',
        
      },
      {
        text: 'Toate',
       
      }
      ],
      wasteCodeFilter: null,
      discardTypeFilter: null,
      dateFilter: null,
      deleteDialog: false,
      customDateFilterValue: 'CUSTOM',
      pickerDates: [],
      dateRangeFilter: {
        show: false, startDate: null, endDate: null, text: null
      },
      showGenerateAnnexDialog: false,
      showGoToConfigsDialog: false

    };
  },
  watch: {
    discardTypeFilter() {
      if (this.discardTypeFilter) {
        this.filters['discardType'] = this.discardTypeFilter
      } else {
        delete this.filters.discardType
      }
      this.clearOptions()
      this.getWasteRecords()
    },
    wasteCodeFilter() {
      if (this.wasteCodeFilter) {
        this.filters['wasteCode'] = this.wasteCodeFilter.wasteCode.code
      } else {
        delete this.filters.wasteCode
      }
      this.clearOptions()
      this.getWasteRecords()
    },
    dateFilter() {
      if (this.dateFilter) {
        this.filters['registerDate'] = this.dateFilter
        if(this.dateFilter == this.customDateFilterValue) {
          this.filters.dateRange = [this.dateRangeFilter.startDate, this.dateRangeFilter.endDate];
        } else {
          this.resetRangeInfo();
          this.filters.dateRange = [];
        }
      } else {
        this.resetRangeInfo();
        delete this.filters.registerDate
        delete this.filters.dateRange
      }
      this.clearOptions()
      this.getWasteRecords()
    },
    options(newO, oldO) {
      if(!oldO.page) {
        return
      }
      // console.log("options", newO, oldO)
      this.getWasteRecords()
    },
    firstWorkingPoint() {
      if (this.firstWorkingPoint) {
        this.currentWorkingPoint = this.firstWorkingPoint
        this.changeContext()
      }
    }
  },
  computed: {
    canPerformOrganizationChanges() {
      return this.$store.state.auth.canPerformOrganizationChanges
    },
    canPerformBasicCRUD() {
      return this.$store.state.auth.canPerformBasicCRUD
    },
    ListCategories() {
      return ListCategories
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    user() {
      return this.$store.state.auth.user
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    firstWorkingPoint() {
      let wp = this.$store.state.workingPoints.firstWorkingPoint
      if (wp) {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
      }
      return wp
    },
    wasteRecords() {
      return this.$store.state.wasteRecords.wasteRecords
    },
    wasteCodesPerWorkingPoint() {
      return this.$store.state.wasteCodesPerWorkingPoint.wasteCodesPerWorkingPoint
    },
    activeWasteCodesPerWorkingPoint() {
      return this.wasteCodesPerWorkingPoint.filter(wc => wc.isActive).map(wc => {
        wc.displayName = `${wc.alias ||wc.wasteCode.code} (${wc.wasteCode.name})`
        return wc
      })
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    partners() {
      return this.$store.state.partners.partners
    },
    loadingExport() {
      return this.$store.state.wasteRecords.loadingExport
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints.map(wp => {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      }).filter(wp => wp.isActive)
    },
    managerWorkingPoints() {
      if (this.canPerformOrganizationChanges) {
        // nu e gueest
        return this.workingPoints
      }
      if (this.user.permissions) {
        let managerPerm = this.user.permissions.find(p => p.role == 'MANAGER')
        if (managerPerm) {
          return this.workingPoints.filter(wp => managerPerm.workingPoints.includes(wp._id))
        }
      }
      return []
    },
    recorderWorkingPoints() {
      if (this.canPerformOrganizationChanges) {
        // nu e gueest
        return this.workingPoints
      }
      if (this.user.permissions) {
        let recorderPerm = this.user.permissions.find(p => p.role == 'DATA_RECORDER')
        if (recorderPerm) {
          return this.workingPoints.filter(wp => recorderPerm.workingPoints.includes(wp._id))
        }
      }
      return []
    },
  },
  methods: {
    checkIfAnnexAvailable(wasteRecord) {
      let wc = this.wasteCodesPerWorkingPoint.find(wc => wc.wasteCode._id === wasteRecord.wasteCode._id)
      if(wc && !wc.wasteCode.isDangerous && wasteRecord.partnerDestination && wasteRecord.partnerTransportator) {
        return true;
      }
      return false
    },
    canAddRecord(workingPoint) {
      return this.managerWorkingPoints.map(wp => wp._id).includes(workingPoint._id) ||
        this.recorderWorkingPoints.map(wp => wp._id).includes(workingPoint._id)
    },
    openDeleteDialog(wasteRecord) {
      this.currentWasteRecord = wasteRecord
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.currentWasteRecord = null
      this.deleteDialog = false
    },
    downloadAnnex(wasteRecord) {
      let fileLink = document.createElement('a');
      fileLink.href = wasteRecord.annexUrl;
      fileLink.setAttribute('target', '_blank');
      fileLink.click();
    },
    downloadDeliveryProof(wasteRecord) {
      let fileLink = document.createElement('a');
      fileLink.href = wasteRecord.deliveryProofUrl;
      fileLink.setAttribute('target', '_blank');
      fileLink.click();
    },
    openAnnexDialog(wasteRecord) {
      let foundWP = this.workingPoints.find((wp) => wp._id === wasteRecord.workingPoint._id)
      
      if(foundWP) {
        if(!foundWP.annexSeries || !foundWP.annexStartNumber) {
          // open redirect to conf
          this.showGoToConfigsDialog = true;
        } else {
          this.currentWasteRecord = wasteRecord
          this.annexWorkingPoint = foundWP
          this.showGenerateAnnexDialog = true;
        }
      }
    },
    closeGenerateAnnexDialog() {
      this.getWasteRecords()
      this.showGenerateAnnexDialog = false;
      this.currentWasteRecord = null
    },
    closeGoToConfigsDialog() {
      this.showGoToConfigsDialog = false;
    },
    deleteAnnex(wasteRecord) {
      this.$store.dispatch('deleteDocument', {
        model: wasteRecord,
        type: 'annex',
        cb: () => {
          this.getWasteRecords()
        }
      });
    },
    deleteDeliveryProof(wasteRecord) {
      this.$store.dispatch('deleteDocument', {
        model: wasteRecord,
        type: 'deliveryProof',
        cb: () => {
          this.getWasteRecords()
        }
      });
    },
    uploadDeliveryProof(wasteRecord) {
      this.currentWasteRecord = wasteRecord
      let input = document.getElementById("uploadFile");
      input.click();
      input.addEventListener("change", this.handleFiles, false);
    },
    handleFiles() {
      this.file = document.getElementById("uploadFile").files[0];
      this.$store.dispatch('uploadDeliveryProof', {
        wasteRecordId: this.currentWasteRecord._id,
        workingPointId: this.currentWasteRecord.workingPoint._id,
        file: this.file,
        cb: () => {
          this.getWasteRecords()
        }
      });
    },
    notFinalized(wasteRecord) {
      return wasteRecord.status != 'FINALIZAT'
    },
    finalizeWasteRecord(wasteRecord) {
      this.$store.dispatch('finalizeWasteRecord', {
        model: wasteRecord,
        cb: () => {
          this.getWasteRecords()
        }
      });
    },
    deleteWasteRecord(wasteRecord) {
      this.$store.dispatch('deleteWasteRecord', {
        id: wasteRecord._id,
        cb: () => {
          this.getWasteRecords()
        }
      });
    },
    exportExcel() {
      this.$store.dispatch("exportExcel", {
        workingPointId: this.currentWorkingPoint._id,
        cb: (response) => {
          let fileName = "Raport.xlsx"
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement('a');
          fileLink.href = fileUrl;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink)
          fileLink.click();
        }
      })
    },
    exportRecords() {
      this.$store.dispatch("exportRecords", {
        workingPointId: this.currentWorkingPoint._id,
        filters: this.filters,
        cb: (response) => {
          let fileName = `Evidenta_deseuri_${this.currentWorkingPoint.company.companyName}_${this.currentWorkingPoint.name}.xlsx`
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement('a');
          fileLink.href = fileUrl;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink)
          fileLink.click();
        }
      })
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    resetRangeInfo() {
      this.dateRangeFilter.startDate = null;
      this.dateRangeFilter.endDate = null;
      this.dateRangeFilter.text = null;
      this.pickerDates = [];
    },
    savePickedDates() {
      if(this.pickerDates.length != 2) {
        return
      }

      if(new Date(this.pickerDates[0]) > new Date(this.pickerDates[1])) {
        this.pickerDates.reverse();
      }
      
      if(this.dateFilter && this.dateFilter == this.customDateFilterValue) {
        this.dateRangeFilter.startDate = this.pickerDates[0];
        this.dateRangeFilter.endDate = this.pickerDates[1];
        let filterText = `${this.formatDate(this.pickerDates[0])} - ${this.formatDate(this.pickerDates[1])}`;
        this.dateRangeFilter.text = filterText;

        this.filters['registerDate'] = this.dateFilter;
        this.filters.dateRange = [this.dateRangeFilter.startDate, this.dateRangeFilter.endDate];
        this.clearOptions();
        this.getWasteRecords();
        this.dateRangeFilter.show = false;

      } else {
        this.dateRangeFilter.startDate = this.pickerDates[0];
        this.dateRangeFilter.endDate = this.pickerDates[1];
        let filterText = `${this.formatDate(this.pickerDates[0])} - ${this.formatDate(this.pickerDates[1])}`;
        this.dateRangeFilter.text = filterText;
        this.dateFilter = this.customDateFilterValue;
        this.dateRangeFilter.show = false;
      }
    },
    changeContext() {
      if (!this.currentWorkingPoint) {
        return
      }
      this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
        workingPointId: this.currentWorkingPoint._id,
      })
      
      this.getWasteRecords()
    },
    filterIsActive(type, value) {
      return this.filters[type] == value
    },
    clearOptions() {
      this.options.page = 1
      this.options.itemsPerPage = 25
    },
    getWasteRecords() {
      // if (dateFilter) {
      //     this.filters['registerDate'] = dateFilter
      // } else {
      //     delete this.filters.registerDate
      // }
      this.$store.dispatch("fetchWasteRecordsPerWorkingPoint", {
        workingPointId: this.currentWorkingPoint._id,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        filters: this.filters
      })
      
    },
    listsByCategory(category) {
      return this.lists[category]
    },
    partnersByWorkingPoint(workingPoint) {
      if (!workingPoint) {
        return []
      }
      let partners = this.partners.filter(p => p.isActive)
      // let partners = this.partners.filter(p => p.workingPoint._id == workingPoint._id)
      return partners
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    openWasteRecordDialog(wasteCodePerWorkingPoint) {
      this.wasteRecordDialog = true
      this.wcToAdd = null
      this.currentWasteCodePWP = wasteCodePerWorkingPoint
    },
    openUpdateDialog(wasteRecord) {
      this.wasteRecordDialog = true
      this.currentWasteCodePWP = wasteRecord
      this.updating = true
    },
    closeWasteRecordDialog() {
      this.wasteRecordDialog = false
      this.updating = false
      this.currentWasteCodePWP = null
    },
    getPartners() {
      this.$store.dispatch('fetchPartners');
    },
  },
  created() {
    this.$store.dispatch('fetchLists', {})
    this.getPartners()
    this.$store.dispatch('fetchWorkingPoints', {lean: true})
  }
};
</script>
<style scoped>
.alignedRow {
  margin-left: 2px
}
.twoRowSpan {
  max-width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
