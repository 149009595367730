var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',{attrs:{"headline":'Configurari '}},[_c('v-container',[_c('v-form',{ref:"form",staticClass:"w-full padded p-5"},[_c('h3',{staticClass:"font-semibold text-xl text-gray-800 leading-tight mb-3"},[_vm._v(" Serie si numar Anexa 3 ")]),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.workingPoints,"item-text":"displayName","label":"Alege punctul de lucru","item-value":"_id","return-object":""},model:{value:(_vm.currentWorkingPoint),callback:function ($$v) {_vm.currentWorkingPoint=$$v},expression:"currentWorkingPoint"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v("Serie Anexa 3")])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Serie Anexa 3 *","required":"","maxlength":"10","counter":"","clearable":"","rules":[
              function (v) { return !!v || "Completeaza serie Anexa 3"; },
              function (v) { return !v || (v && v.length <= 10) || 'Max 10 caractere'; },
              function (v) { return !v || /^[a-zA-Z0-9\s]+$/.test(v) || "Seria poate contine doar cifre si litere"; }
            ]},model:{value:(_vm.model.annexSeries),callback:function ($$v) {_vm.$set(_vm.model, "annexSeries", $$v)},expression:"model.annexSeries"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v("Numar start Anexa 3")])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"type":"number","placeholder":"Numar start Anexa 3 *","required":"","step":"1","min":"0","max":"99999999999999999999999999","clearable":"","rules":[
              function (v) { return !!v || "Completeaza numar start Anexa 3"; },
              function (v) { return Number(v) >= 0 || "Numarul trebuie sa fie pozitiv"; },
              function (v) { return !v || /^[0-9]+$/.test(v) || "Numarul trebuie sa fie intreg"; }
            ]},model:{value:(_vm.model.annexStartNumber),callback:function ($$v) {_vm.$set(_vm.model, "annexStartNumber", $$v)},expression:"model.annexStartNumber"}})],1)],1)],1),_c('v-btn',{staticClass:"mainButtonYes",attrs:{"type":"button","text":"","disabled":_vm.isDisabledSave},on:{"click":function($event){return _vm.save()}}},[_vm._v("Salveaza")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }