<template>
  <auth-layout :headline="'Configurari '">
    <v-container>
      <!-- <v-row>
        <v-select v-model="currentWorkingPoint" :items="workingPoints" item-text="displayName"
        label="Alege punctul de lucru" item-value="_id" return-object />
      </v-row> -->
      <v-form class="w-full padded p-5" ref="form">
        <h3 class="font-semibold text-xl text-gray-800 leading-tight mb-3">
          Serie si numar Anexa 3
        </h3>
        
        <div>
          <v-row>
            <v-col cols="12">
              <v-select v-model="currentWorkingPoint" :items="workingPoints" item-text="displayName"
                label="Alege punctul de lucru" item-value="_id" return-object />
            </v-col>
            <v-col cols="4">
              <v-subheader>Serie Anexa 3</v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field type="text" placeholder="Serie Anexa 3 *" v-model="model.annexSeries" required 
              maxlength="10" counter clearable
              :rules="[
                v => !!v || `Completeaza serie Anexa 3`,
                v => !v || (v && v.length <= 10) || 'Max 10 caractere',
                v => !v || /^[a-zA-Z0-9\s]+$/.test(v) || `Seria poate contine doar cifre si litere`
              ]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-subheader>Numar start Anexa 3</v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field type="number" placeholder="Numar start Anexa 3 *" v-model="model.annexStartNumber" required 
              step="1" min="0" max="99999999999999999999999999" clearable
              :rules="[
                v => !!v || `Completeaza numar start Anexa 3`,
                v => Number(v) >= 0 || `Numarul trebuie sa fie pozitiv`,
                v => !v || /^[0-9]+$/.test(v) || `Numarul trebuie sa fie intreg`
              ]" />
            </v-col>
          </v-row>
        </div>
        <v-btn type="button" class="mainButtonYes" text :disabled="isDisabledSave" @click="save()">Salveaza</v-btn>
      </v-form>
    </v-container>
    
  </auth-layout>  
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
export default {
  data () {
    return {
      currentWorkingPoint: null,
      isDisabledSave: false,
      model: {

      }
    }
  },
  components: {
    AuthLayout,
  },
  watch: {
    firstWorkingPoint() {
      if (this.firstWorkingPoint) {
        this.currentWorkingPoint = this.firstWorkingPoint
        // this.changeContext()
      }
    },
    currentWorkingPoint() {
      this.model = {
        _id: this.currentWorkingPoint._id,
        annexSeries: this.currentWorkingPoint.annexSeries || null,
        annexStartNumber: this.currentWorkingPoint.annexStartNumber || null,
      }
    }
  },
  computed: {
    firstWorkingPoint() {
      let wp = this.$store.state.workingPoints.firstWorkingPoint
      if (wp) {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
      }
      return wp
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints.map(wp => {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      }).filter(wp => wp.isActive)
    },
    
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.model.annexSeries = this.model.annexSeries.trim();
      // TO DO delete '.' from number

      if(this.model.annexSeries == this.currentWorkingPoint.annexSeries 
      && this.model.annexStartNumber == this.currentWorkingPoint.annexStartNumber) {
        return
      }

      if(!this.model.annexSeries) {
        return
      }

      this.$store.dispatch("updateWorkingPoint", {
        id: this.model._id,
        model: this.model,
        cb: () => {}
      })
    }
  },
  created() {
    this.$store.dispatch('fetchWorkingPoints', {lean: true})
  }
}

</script>