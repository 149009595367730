<template>
<div style="margin: 10px">
  <!-- <section-headline>
    Rapoarte
  </section-headline> -->
  <LayoutHeader title="Rapoarte" :dontDistrurbFree="true"/>
  <div class="layoutBody">
    <v-form ref="form">
      <v-select v-model="currentWorkingPoint" :items="workingPoints" item-text="displayName" label="Alege punctul de lucru" item-value="_id" return-object @input="changeContext()"  :rules="[v => !!v || 'Campul este obligatoriu']" />
      <v-select v-model="currentYear" :items="wasteRecordYears" label="Alege anul" :rules="[v => !!v || 'Campul este obligatoriu']" />
      <v-select v-model="reportType" :items="reportTypes" label="Alege tipul de raport" return-object  :rules="[v => !!v || 'Campul este obligatoriu']" /> 
    </v-form>
    <v-layout v-if="!userDisabled">
      <v-btn @click="exportReport()" class="text-gray-500 primary">
        <span>Descarca raport</span>
      </v-btn>
      <v-divider style="border-block: white; max-width: 50px;"/>
      <v-btn v-if=" isAdmin && reportType && reportType.value == 'PRODDES'" @click="openMailDialog()" class="text-gray-500 primary">
        <span>Trimite raportare catre APM</span>
      </v-btn>
      <v-divider style="border-block: white; max-width: 10px;"/>
      <v-progress-circular indeterminate color="primary" v-if="loadingReport"></v-progress-circular>
    </v-layout>
    <v-container class="mt-6">
    <v-row>
      <v-col cols="12">

        <p>Pentru a putea depune raportarea la Agentia pentru Protectia Mediului exista 2 variante:</p>
        <v-list>
          <v-list-item>
            <div>
            <span>Va creati cont in SIM si depundeti acolo raportarea - am scris un articol pe aceasta tema aici: </span>
            <a href="https://registrul-deseurilor.ro/2024/01/10/creeza-ti-cont-in-aplicatia-anpm-sim-pentru-a-raporta-evidenta-gestiunii-deseurilor-generate/" target="_blank">
              Creeaza-ti cont in aplicatia ANPM - SIM pentru a raporta Evidenta gestiunii deseurilor generate - Aplicatia Registrul Deseurilor
            </a>
          </div>
          </v-list-item>
          <v-list-item>
            Trimiteti raportul anual Proddes, dupa ce il semnati si stampilati, pe adresa de email APM {{anpmEmail.county}} - {{anpmEmail.email}} ( adresa de email APM din judetul in care se afla punctul de lucru ).
          </v-list-item>
        </v-list>
        
      </v-col>
    </v-row>
  </v-container>
  </div>
  <ConfirmMailDialog v-if="mailDialog" :closeDialog="closeMailDialog" :sendMailAction="sendMail" :workingPoint="currentWorkingPoint" :exportReport="exportReport" :year="currentYear"/>
</div>
</template>
<script>
import {
  ListCategories
} from '../common/ListCategories';
import AddButton from "../components/Buttons/AddButton.vue";
import ConfirmMailDialog from '../components/Modals/ConfirmMailDialog.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import SubHeader from '../components/Typography/SubHeader.vue';
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
export default {
  components: {
    LayoutHeader,
    SectionHeadline,
    AddButton,
    SubHeader,
    ConfirmMailDialog
},
  data() {
    return {
      wasteRecordDialog: false,
      mailDialog: false,
      currentWorkingPoint: null,
      currentYear: null,
      options: {},
      expanded: [],
      currentWasteCodePWP: null,
      updating: false,
      wcToAdd: null,
      reportTypes: [{
          // text: "Evidenta deseuri cf. H.G. 856/2002",
          text: "Raport lunar cf HG 856/2002",
          value: "DEFAULT"
        },
        {
          // text: "PRODDES",
          text: "Raport anual Proddes",
          value: "PRODDES"
        },
        {
          text: "Raport anual Proddes PDF",
          value: "PRODDES_PDF"
        },
        {
          // text: "Raport centralizat",
          text: "Raport centralizat anual",
          value: "CENTRALIZED"
        }
      ],
      reportType: null
    };
  },
  watch: {
    firstWorkingPoint() {
      if (this.firstWorkingPoint) {
        this.currentWorkingPoint = this.firstWorkingPoint
        this.changeContext()
      }
    },
    wasteRecordYears() {
      if (this.wasteRecordYears.length) {
        this.currentYear = this.wasteRecordYears[0]
      }
    }
  },
  computed: {
    ListCategories() {
      return ListCategories
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    user() {
      return this.$store.state.auth.user
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    firstWorkingPoint() {
      let wp = this.$store.state.workingPoints.firstWorkingPoint
      if (wp) {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
      }
      return wp
    },
    wasteRecordYears() {
      return this.$store.state.wasteRecords.wasteRecordYears
    },
    anpmEmail() {
      return this.$store.state.wasteRecords.anpmEmail
    },
    loadingReport() {
      return this.$store.state.wasteRecords.loadingReport
    },
    wasteCodesPerWorkingPoint() {
      return this.$store.state.wasteCodesPerWorkingPoint.wasteCodesPerWorkingPoint
    },
    activeWasteCodesPerWorkingPoint() {
      return this.wasteCodesPerWorkingPoint.filter(wc => wc.isActive)
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    partners() {
      return this.$store.state.partners.partners
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints.map(wp => {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      }).filter(wp => wp.isActive)
    }
  },
  methods: {
    openMailDialog() {
      this.mailDialog = true
      this.$store.dispatch("getANPMEmail", this.currentWorkingPoint._id)
    },
    closeMailDialog() {
      this.mailDialog = false
    },
    notFinalized(wasteRecord) {
      return wasteRecord.status != 'FINALIZAT'
    },
    sendMail() {
      if(this.userDisabled || !this.$refs.form.validate()) {
        return
      }
      this.$store.dispatch("sendMailToANPM", {
        workingPointId: this.currentWorkingPoint._id,
        year: this.currentYear,
        county: this.anpmEmail.county,
        cb: (response) => {
        }
      })
    },
    getReportName() {
      let name = ''
      switch (this.reportType.value) {
        case 'CENTRALIZED':
          name = `Raport_centralizat_anual__${this.currentYear}.docx`
          break;
        case 'PRODDES_PDF':
          name = `Raport_anual_Proddes__${this.currentYear}.pdf`
          break;
        case 'PRODDES':
          name = `Raport_anual_Proddes__${this.currentYear}.xlsx`
          break;
        default:
          name = `Raport_lunar_cf_HG856_2002__${this.currentYear}.xlsx`
          break;
      }
      return name;
    },
    exportReport() {
      if(this.userDisabled || !this.$refs.form.validate()) {
        return
      }
      this.$store.dispatch("exportReport", {
        workingPointId: this.currentWorkingPoint._id,
        year: this.currentYear,
        reportType: this.reportType.value,
        cb: (response) => {
          // let fileName = this.reportType.value == 'CENTRALIZED' ? "Raport.docx" :
          //    (this.reportType.value == 'PRODDES_PDF' ? "Raport.pdf": "Raport.xlsx")
          let fileName = this.getReportName();
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement('a');
          fileLink.href = fileUrl;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink)
          fileLink.click();
        }
      })
    },
    changeContext() {
      if (!this.currentWorkingPoint) {
        return
      }
      // this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
      //   workingPointId: this.currentWorkingPoint._id,
      // })
      this.$store.dispatch("fetchWasteRecordsYearsPerWorkingPoint", {
        workingPointId: this.currentWorkingPoint._id,
      })
      this.$store.dispatch("getANPMEmail", this.currentWorkingPoint._id)
    },
    listsByCategory(category) {
      return this.lists[category]
    },
  },
  created() {
    this.$store.dispatch('fetchWorkingPoints', {lean: true})
    
  }
};
</script>
