import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    wasteRecords: [],
    wasteRecordStatistics: [],
    wasteRecordYears: [],
    loadingReport: false,
    anpmEmail: null,
    loadingEmail: false,
    loadingExport: false,
    loadingAnnex: false
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_WASTE_RECORDS_YEARS(state, data) {
      state.wasteRecordYears = data
    },
    SET_WASTE_RECORDS(state, data) {
      state.wasteRecords = data
    },
    SET_WASTE_RECORDS_STATISTICS(state, data) {
      state.wasteRecordStatistics = data
    },
    SET_LOADING(state, data) {
      state.loadingReport = data
    },
    SET_LOADING_EMAIL(state, data) {
      state.loadingEmail = data
    },
    SET_ANPM_EMAIL(state, data) {
      state.anpmEmail = data
    },
    SET_LOADING_EXPORT(state, data) {
      state.loadingExport = data
    },
    SET_LOADING_ANNEX(state, data) {
      state.loadingAnnex = data
    }
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async generateAnnexPDF({commit}, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        commit('SET_LOADING_ANNEX', true)
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/wasteRecords/generateAnnexPDF`,
          model,
          {
            withCredentials: true,
            // responseType: 'blob'
          },
        );
        commit('SET_LOADING_ANNEX', false)
        if (res.status === 201) {
          toastr.success("Anexa 3 a fost generata!")
        }
        cb()
      } catch (error) {
        console.log(error)
        commit('SET_LOADING_ANNEX', false)
        toastr.error("A aparut o problema, te rugam sa incerci din nou.")
        return this.error;
      }
      
    },
    // async uploadAnnex({commit}, data) {
    //   let wasteRecordId = data.wasteRecordId
    //   let file = data.file
    //   let cb = data.cb
    //   const fd = new FormData();
    //   fd.append("file", file);
    //   try {
    //     const res = await axiosInstance.post(
    //       `${process.env.VUE_APP_API_URL}/files/uploadAnnex3/${wasteRecordId}`,
    //       fd,
    //       {
    //         withCredentials: true,
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //       }
    //     )
    //     if (res.status === 200) {
    //       toastr.success("Campul a fost adaugat!")
    //       cb()
    //     }
    //   } catch(e) {
    //     toastr.error(e)
    //   }
    // },
    async uploadDeliveryProof({commit}, data) {
      let wasteRecordId = data.wasteRecordId
      let workingPointId = data.workingPointId
      let file = data.file
      let cb = data.cb
      const fd = new FormData();
      fd.append("file", file);
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/files/uploadDeliveryProof/${wasteRecordId}/${workingPointId}`,
          fd,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (res.status === 200) {
          toastr.success("Campul a fost adaugat!")
          cb()
        }
      } catch(e) {
        toastr.error(e)
      }
    },
    async exportReport({ commit }, formData) {
      let workingPointId = formData.workingPointId
      let year = formData.year
      let reportType = formData.reportType
      let cb = formData.cb
      // console.log(formData)
      try {
        commit('SET_LOADING', true)
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/wasteRecords/exportReport/${workingPointId}/${year}/${reportType}`,
          {
            withCredentials: true,
            responseType: 'blob'
          }
        );
        cb(res)
        commit('SET_LOADING', false)
      } catch (error) {
        console.log(error)
        commit('SET_LOADING', false)
        return this.error;
      }
    },
    async sendMailToANPM({ commit }, formData) {
      let workingPointId = formData.workingPointId
      let year = formData.year
      let county = formData.county
      let cb = formData.cb
      try {
        commit('SET_LOADING', true)
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/mailANPM/sendReport/${workingPointId}/${year}`,
          {},
          {
            withCredentials: true,
            // responseType: 'blob'
          }
        );
        // cb(res)
        commit('SET_LOADING', false)
        if (res.status === 201) {
          toastr.success(`Un email cu raportarea pentru anul ${year} a fost trimis catre APM ${county}`)
        }
      } catch (error) {
        console.log(error)
        commit('SET_LOADING', false)
        toastr.error("A aparut o problema, te rugam sa incerci din nou.")
        return this.error;
      }
    },
    async getANPMEmail({ commit }, workingPointId) {
      commit('SET_ANPM_EMAIL', null)
      commit('SET_LOADING_EMAIL', true)
      try {
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/mailANPM/byWorkingPoint/${workingPointId}`,
          {
            withCredentials: true,
          }
        );
        // cb(res)
        commit('SET_ANPM_EMAIL', res.data)
        commit('SET_LOADING_EMAIL', false)
      } catch (error) {
        commit('SET_LOADING_EMAIL', false)
        console.log(error)
        return this.error;
      }
    },
    async exportRecords({commit}, options) {
      let cb = options.cb
      let workingPointId = options.workingPointId || ''
      let filters = JSON.stringify(options.filters)
      try {
        commit('SET_LOADING_EXPORT', true)
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/wasteRecords/export/forWorkingPoint/${workingPointId}?filters=${filters}`,
          {
            withCredentials: true,
            responseType: 'blob'
          }
        );
        cb(res)
        commit('SET_LOADING_EXPORT', false)
      } catch (error) {
        console.log(error)
        commit('SET_LOADING_EXPORT', false)
        return this.error;
      }
    },
    async createWasteRecord({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/wasteRecords`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Campul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async updateWasteRecord({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/wasteRecords/${model._id}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async deleteWasteRecord({ commit }, formData) {
      let id = formData.id
      let cb = formData.cb
      try {
        const res = await axiosInstance.delete(
          `${process.env.VUE_APP_API_URL}/wasteRecords/${id}`,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost sters!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async finalizeWasteRecord({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/wasteRecords/finalize/${model._id}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async deleteDocument({ commit }, formData) {
      let model = formData.model
      let documentType = formData.type
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/wasteRecords/deleteDocument/${model._id}/${documentType}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost sters!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async fetchWasteRecordsPerWorkingPoint({ commit }, options) {
      let workingPointId = options.workingPointId || ''
      let page = options.page || 1
      let perPage = options.perPage || 25
      let filters = JSON.stringify(options.filters) 
      // let c
      try {
        let url = `${process.env.VUE_APP_API_URL}/wasteRecords/forWorkingPoint/${workingPointId}/?page=${page}&perPage=${perPage}&filters=${filters}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_WASTE_RECORDS', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchWasteRecordsStatisticsPerWorkingPoint({ commit }, options) {
      let workingPointId = options.workingPointId || ''
      // let interval = options.interval || ''
      let interval = JSON.stringify(options.interval)
      // let c
      try {
        let url = `${process.env.VUE_APP_API_URL}/wasteRecords/statistics/forWorkingPoint/${workingPointId}`
        if(interval && interval.length) {
          url = url + `?interval=${interval}`
        }
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_WASTE_RECORDS_STATISTICS', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchWasteRecordsYearsPerWorkingPoint({ commit }, options) {
      let workingPointId = options.workingPointId || ''
      // let c
      try {
        let url = `${process.env.VUE_APP_API_URL}/wasteRecords/availableYears/forWorkingPoint/${workingPointId}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_WASTE_RECORDS_YEARS', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
